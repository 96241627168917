import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const doBounceAnimation = (targetRef, positionParam, triggerPos) => {
  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: targetRef,
      start: triggerPos ? triggerPos : "top center",
    },
  });
  timeline.from(
    targetRef,
    {
      autoAlpha: 0, // fix for flickering issue, targetRef needs to have visibillity: hidden
      y: 20,
      duration: 1.4,
      ease: "elastic.out(1.5, 0.5)",
    },
    positionParam
  );
};
