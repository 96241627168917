import React, { useEffect, useRef } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as styles from "../../styles/pages/pageWork.module.scss";

import { TagLabel } from "../../components/Label/Label";
import { doBounceAnimation } from "../../animations/twn_doBounceAnimation";

const WorkPage = () => {
  const pageRef = useRef();

  useEffect(() => {
    doBounceAnimation(pageRef.current, 0.2);
  }, []);

  return (
    <div className={styles.page_wrapper} ref={pageRef}>
      <header>
        <h1>Works & Experiments</h1>
        <p>A collection of stuffs.</p>
      </header>
      <SectionWorks />
    </div>
  );
};

export default WorkPage;

const SectionWorks = () => {
  const imageData = useStaticQuery(graphql`
    query imageQueryWorks {
      allFile(filter: { absolutePath: { regex: "/work/" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(transformOptions: { fit: COVER }, layout: FULL_WIDTH, quality: 100)
            }
            name
          }
        }
      }
    }
  `);

  const images = {};
  imageData.allFile.edges.forEach(
    (image) =>
      (images[image.node.name] = { gatsbyImage: image.node.childImageSharp.gatsbyImageData })
  );

  return (
    <section className={styles.section_flex}>
      {listOfWorks.map((work) => (
        <Work data={work} key={work.title} images={images} />
      ))}
    </section>
  );
};

const listOfWorks = [
  {
    title: "Frontend Mentor Challenge",
    category: "Daily Challenge",
    url: "./frontend-mentor-challenge",
    description:
      "A daily challenge for myself to improve both my UIUX and front-end skills, 30 mins day",
    tags: ["UI/UX", "Front-end", "Full-stack"],
    imageName: "workFem",
  },
  {
    title: "Grokking Technical Questions",
    category: "Daily Challenge",
    url: "./grokking-technical-questions",
    description: "Solving one technical problem a day",
    tags: ["Technical Questions"],
    imageName: "workCoding",
  },
  {
    title: "🔒 Land Transport Authority",
    category: "Professional Work",
    url: "",
    description:
      "A collection of works I've done during my time in LTA as a UIUX Designer + Front-end Developer.",
    tags: ["UIUX", "Front-end Developer"],
    imageName: "workLta",
  },
  // {
  //   title: "Figma Plugins",
  //   category: "Side Projects",
  //   url: "./diverse-name-generator",
  //   description: "Developing plugins to help designers and developers work easier",
  //   tags: ["Figma", "Plugins", "Full-stack"],
  //   imageName: "workFigma",
  // },
];

const Work = ({ data, images }) => {
  const { title, category, description, url, tags, imageName } = data;
  const gatsbyImage = getImage(images[imageName].gatsbyImage);

  const renderURL = () => {
    if (!url) {
      return (
        <div className={styles.password} onClick={() => alert("yo")}>
          <h3>{title}</h3>
        </div>
      );
    }
    return (
      <Link to={url}>
        <h3>{title}</h3>
      </Link>
    );
  };

  return (
    <div className="card__hover">
      <div className={`${styles.card_container} shadow`}>
        <div className={styles.card_image}>
          <GatsbyImage image={gatsbyImage} alt="project cover"></GatsbyImage>
        </div>
        <div className={styles.card_content}>
          {renderURL()}
          <p className={styles.card_content_category}>{category}</p>
          <p className={styles.card_content_description}>{description}</p>
          <ul className={styles.card_tags}>
            {tags.map((tag) => (
              <li key={tag}>
                <TagLabel type={tag} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
